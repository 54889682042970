import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore'; // Import Firestore

const firebaseConfig = {
    apiKey: "AIzaSyDTOi8vm4Jsad645PUU3BJ0b_tVv8sg9oA",
    authDomain: "ntcameraonline-2c4b5.firebaseapp.com",
    databaseURL: "https://ntcameraonline-2c4b5-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "ntcameraonline-2c4b5",
    storageBucket: "ntcameraonline-2c4b5.appspot.com",
    messagingSenderId: "813834896718",
    appId: "1:813834896718:web:3c59c770d6c263544eca1f",
    measurementId: "G-WWRD95W9GH"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();
const firestore = firebase.firestore(); // Initialize Firestore
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export { auth, firestore, googleAuthProvider }; // Export firestore along with auth and googleAuthProvider
export default firebase;
