import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Create styled components for your elements
const HomePageContainer = styled.div`
  text-align: center;
  padding: 20px;
`;

const Heading = styled.h1`
  font-size: 2rem;
  color: #333;
`;

const Subheading = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const StyledLink = styled(Link)`
  font-size: 2rem;
  color: #007bff;
  text-decoration: none;
  margin: 0 10px;
  background-color: #007bff; /* Light blue background color */
  color: white; /* White text color */
  padding: 15px 20px; /* Padding around the button text */
  border: solid; /* Remove border */
  border-radius: 15px; /* Rounded corners */
  cursor: pointer; /* Cursor on hover */
  font-size: 30px; /* Font size */
  text-decoration: none; /* Remove underlines from the link */
  display: inline-block; /* Display as a block element */
  margin-right: 20px; /* Add some margin between buttons */
  &:hover {
    background-color: #007bff; /* Darker blue on hover */
  }
  
  &:hover {
    text-decoration: underline;
  }
`;

const HomePage = () => {
  return (
    <HomePageContainer>
      <Heading>Welcome to NT Live Cam</Heading>
      <Subheading>Discover live video streams from around the world.</Subheading>

      <StyledLink to="/login">Login</StyledLink>
      <span> | </span>
      <StyledLink to="/signup">Signup</StyledLink>
    </HomePageContainer>
  );
};

export default HomePage;
