import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { auth, googleAuthProvider } from '../../firebase';

const LoginContainer = styled.div`
  text-align: center;
  padding: 50px;
  margin: 15px;
  border-radius: 25px;
  background-color: #2222;
  font-size: 24px;
  border: solid;
`;

const LoginButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  margin: 15px;
  cursor: pointer;
  font-size: 22px;
  &:hover {
    background-color: #0056b3;
  }
`;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      setError(''); // Clear any previous error messages
      const userCredential = await auth.signInWithEmailAndPassword(email, password);
      // Check if the user's email is verified
      if (!userCredential.user.emailVerified) {
        setError('Please verify your email before logging in.');
        return;
      }
      // User successfully logged in
      navigate('/dashboard');
    } catch (error) {
      setError('Invalid email or password. Please try again.'); // Set error message
      console.error('Error logging in:', error);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setError(''); // Clear any previous error messages
      const userCredential = await auth.signInWithPopup(googleAuthProvider);
      // Check if the user's email is verified
      if (!userCredential.user.emailVerified) {
        setError('Please verify your email before logging in.');
        return;
      }
      // User successfully signed in with Google
      navigate('/dashboard');
    } catch (error) {
      setError('Error signing in with Google. Please try again.'); // Set error message
      console.error('Error signing in with Google:', error);
    }
  };

  return (
    <LoginContainer>
      <h1>Log In</h1>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}
      <LoginButton onClick={handleLogin}>Log In</LoginButton>
      <LoginButton onClick={handleGoogleSignIn}>Log In with Google</LoginButton>
      <p>Don't have an account? <Link to="/signup">Sign Up</Link></p>
    </LoginContainer>
  );
};

export default Login;
