// ViewerPhone.js
import React, { useState, useRef } from 'react';
import { firestore } from '../../firebase'; // Import Firestore

const ViewerPhone = () => {
  const [cameraId, setCameraId] = useState('');
  const [password, setPassword] = useState('');
  const videoRef = useRef(null); // Initialize with null

  const handleViewStream = async () => {
    try {
      // Fetch camera details from Firestore
      const camerasRef = firestore.collection('cameras');
      const querySnapshot = await camerasRef.where('cameraId', '==', cameraId).where('password', '==', password).get();
      
      if (!querySnapshot.empty) {
        // Camera ID and password match
        const streamURL = 'your-camera-stream-url'; // Replace with actual camera stream URL
        const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true }); // Get user media
        videoRef.current.srcObject = mediaStream; // Set srcObject with MediaStream
      } else {
        alert('Invalid camera ID or password. Please try again.');
      }
    } catch (error) {
      console.error('Error viewing stream:', error);
      alert('Failed to view stream. Please try again.');
    }
  };

  return (
    <div>
      <h2>View Stream</h2>
      <input type="text" placeholder="Camera ID" value={cameraId} onChange={(e) => setCameraId(e.target.value)} />
      <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <button onClick={handleViewStream}>View Stream</button>
      <video ref={videoRef} autoPlay playsInline controls /> {/* Render video stream */}
    </div>
  );
};

export default ViewerPhone;
