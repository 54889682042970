// src/components/dashboard/Dashboard.js
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const DashboardContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const OptionsContainer = styled.div`
  margin-top: 20px;
`;

const OptionLink = styled(Link)`
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Dashboard = () => {
  return (
    <DashboardContainer>
      <h2>Dashboard</h2>
      <p>Welcome to the Dashboard!</p>

      <OptionsContainer>
        <h3>Options:</h3>
        <OptionLink to="/dashboard/camera">Camera Phone</OptionLink>
        <OptionLink to="/dashboard/viewer">Viewer Phone</OptionLink>
      </OptionsContainer>
    </DashboardContainer>
  );
};

export default Dashboard;
