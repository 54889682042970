import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Login from './components/auth/Login';
import Signup from './components/auth/SignUp';



import Home from './pages/HomePage';
import Dashboard from './pages/Dashboard';
import CameraPhone from './components/camera/CameraPhone';
import ViewerPhone from './components/camera/ViewerPhone';
import socket from './services/socket'; // Import the socket.io setup file

function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Make a GET request to your backend server
    axios.get('/api/data') // Use relative URL
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

    return () => {
      // Clean up the socket connection when component unmounts
      socket.disconnect();
    };
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          
          
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/camera" element={<CameraPhone />} />
          <Route path="/dashboard/viewer" element={<ViewerPhone />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
