import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { auth, googleAuthProvider, firestore } from '../../firebase';

const SignUpContainer = styled.div`
  text-align: center;
  padding: 50px;
  margin: 15px;
  border-radius: 25px;
  background-color: #2222;
  font-size: 24px;
  border: solid;
`;

const SignUpButton = styled.button`
  background-color: #007bff; 
  color: white; 
  padding: 10px 20px; 
  border: none; 
  border-radius: 15px; 
  margin: 15px; 
  cursor: pointer; 
  font-size: 22px; 
  &:hover {
    background-color: #0056b3;
  }
`;

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSignUp = async () => {
    try {
      // Create user in Firebase Authentication
      const userCredential = await auth.createUserWithEmailAndPassword(email, password);

      // Save user data to Firestore
      await firestore.collection('users').doc(userCredential.user.uid).set({
        email: email,
        // Add other user data as needed
      });

      // Send verification email
      await userCredential.user.sendEmailVerification();

      // Set success message
      setSuccessMessage('Sign-up successful! Verification email has been sent. Please verify your email before logging in.');
    } catch (error) {
      console.error('Error signing up:', error);
      setSuccessMessage('');
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await auth.signInWithRedirect(googleAuthProvider);
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  return (
    <SignUpContainer>
      <h1>Sign Up</h1>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <SignUpButton onClick={handleSignUp}>Sign Up</SignUpButton>
      <SignUpButton onClick={handleGoogleSignIn}>Sign Up with Google</SignUpButton>
      {successMessage && <p>{successMessage}</p>}
      <p>Already have an account? <Link to="/login">Log In</Link></p>
    </SignUpContainer>
  );
};

export default Signup;
